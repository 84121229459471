













































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import FieldAddress from '@/components/FieldAddress.vue';
import DELIVERY from '@/graphql/queries/DeliveryLocation.graphql';
import DELIVERY_CREATE from '@/graphql/mutations/DeliveryLocationCreate.graphql';
import DELIVERY_UPDATE from '@/graphql/mutations/DeliveryLocationUpdate.graphql';
import { required, decimal } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import numberOrNull from '@/utils/numberOrNull';
import { CODES } from '@/utils/constants';
import {
  DeliveryLocation,
  DeliveryLocationCreateMutationVariables,
  DeliveryLocationQueryVariables,
  DeliveryLocationUpdateMutationVariables,
} from '@/types/schema';
import { ApolloQueryResult } from 'apollo-client';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'AdminDairiesAdd',
  components: {
    AppMain,
    AppForm,
    FieldAddress,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        name: '',
        address: {
          street: '',
          city: '',
          state: '',
          zip: '',
          notes: '',
          latitude: '' as string | number,
          longitude: '' as string | number,
        },
        miles: '' as string | number,
      },
      delivery: {} as DeliveryLocation,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        address: {
          street: { required },
          city: { required },
          state: { required },
          zip: { required },
          latitude: { decimal },
          longitude: { decimal },
        },
        miles: { decimal },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(delivery: DeliveryLocation) {
      if (!delivery) return;
      this.form = {
        name: delivery.name || '',
        miles: delivery.miles || '',
        address: {
          street: delivery.address ? delivery.address.street : '',
          city: delivery.address ? delivery.address.city : '',
          state: delivery.address ? delivery.address.state : '',
          zip: delivery.address ? delivery.address.zip : '',
          notes: delivery.address ? delivery.address.notes || '' : '',
          latitude: delivery.address ? delivery.address.latitude || '' : '',
          longitude: delivery.address ? delivery.address.longitude || '' : '',
        },
      };
    },
    async saveDelivery() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updateDelivery();
        else await this.addDelivery();
        this.$router.replace({ name: 'admin-dairies' });
        this.$notify({
          text: 'Dairy saved!',
          type: 'success',
          duration: 6000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    addDelivery() {
      return this.$apollo.mutate({
        mutation: DELIVERY_CREATE,
        variables: {
          input: {
            name: this.form.name,
            address: {
              street: this.form.address.street,
              city: this.form.address.city,
              state: this.form.address.state,
              zip: this.form.address.zip,
              notes: this.form.address.notes,
              latitude: numberOrNull(this.form.address.latitude),
              longitude: numberOrNull(this.form.address.longitude),
            },
            miles: this.form.miles || null,
          },
        } as DeliveryLocationCreateMutationVariables,
      });
    },
    updateDelivery() {
      return this.$apollo.mutate({
        mutation: DELIVERY_UPDATE,
        variables: {
          input: {
            id: this.delivery.id,
            name: this.form.name,
            address: {
              street: this.form.address.street,
              city: this.form.address.city,
              state: this.form.address.state,
              zip: this.form.address.zip,
              notes: this.form.address.notes,
              latitude: numberOrNull(this.form.address.latitude),
              longitude: numberOrNull(this.form.address.longitude),
            },
            miles: this.form.miles || null,
          },
        } as DeliveryLocationUpdateMutationVariables,
      });
    },
  },
  apollo: {
    delivery: {
      query: DELIVERY,
      variables(): DeliveryLocationQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ delivery: DeliveryLocation }>) {
        if (data) this.fillForm(data.delivery);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-dairies' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
    },
  },
});
